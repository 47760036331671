import { useCallback, useEffect, useRef, useState } from 'react'
import { LngLatLike, MapMouseEvent, MapRef } from 'react-map-gl/mapbox'
import { useSearchParams } from 'react-router'
import { Feature, Geometry } from 'geojson'
import { FeatureSelector, LngLat } from 'mapbox-gl'
import { StringParam, useQueryParams } from 'use-query-params'

export const DEFAULT_MIN_INTERACTOIN_ZOOM_LEVEL = 9
export const LINE_LAYER_MIN_INTERACTION_ZOOM_LEVEL = 13

export type FeatureStateFeature = FeatureSelector & Feature<Geometry>

export type FeatureState<PropertiesType> = {
  feature: FeatureStateFeature
  lngLat: LngLat
  id: string | number | undefined
  properties: PropertiesType
  origin: FeatureStateOrigin
}

export enum MapFeatureState {
  HOVERED = 'HOVERED',
  SELECTED = 'SELECTED',
}

type MapSource = { sourceId: string; sourceLayer?: string }

type UseFeatureStateOptions = {
  minZoomLevel?: number
  queryParamKeysToIgnore?: string[]
}
type FeatureQuery<PropertiesType> = {
  lngLat: LngLatLike
  featureId: string
  idPropertyKeyOnFeature?: keyof PropertiesType
  origin: FeatureStateOrigin
}
type UseFeatureStateReturnType<PropertiesType> = {
  featureState: FeatureState<PropertiesType> | undefined
  updateFeatureStateFromMouseEvent: (event: MapMouseEvent) => void
  setMapFeatureToQuery: (featureQuery: FeatureQuery<PropertiesType> | undefined) => void
  clearFeatureState: () => void
}

enum FeatureStateOrigin {
  QUERY_PARAM = 'query_param',
  // allowed map interaction types (MapMouseEvent.type)
  MOUSEMOVE = 'mousemove',
  CLICK = 'click',
}
const featureStateOrigins = Object.values(FeatureStateOrigin) as string[]

const featureStateStore = {} as Record<keyof typeof MapFeatureState, FeatureStateFeature>

/**
 * Utility hook for react-map-gl maps to handle the feature state for a layer. This hook can be
 * used for multiple feature states for multiple layers. Use to show a differnt style on a shape when
 * that shape is hovered over or clicked. Also use to show a Popup at the lngLat with the available
 * properties on the FeatureState object.
 *
 * Can be used for clicks or hovers, simply call `updateFeatureState` from that respective function
 *
 * Boolean Feature State Only:
 * As written, this hook can only handle boolean feature states, if a string/number feature state
 * value is required, this hook can be refactored, or a separate hook can be written.
 *
 * Multiple Popups:
 * If it's desired to only show one popup at a time, then both feature state hooks should be used,
 * and just conditionally render one of the popups based on whether or not the other's feature state
 * is set.
 *
 * @param map The react-map-gl Map reference object
 * @param sources The sources that will activate this feature state.
 * The only known case to use multiple sources is when you may have multiple vector tile sources
 * that you want to behave the same (e.g. multiple shape layers). In all other cases, you should use
 * one `useFeatureState` hook per source when the layers are styled differently.
 * NOTE: The source-layer is different from the layer id, so don't pass in layer id into the source-layer
 * @param getFeatureStateMap key to use as boolean value for feature state
 * (e.g. for a hovered state, `featureStateKey: 'hovered'` can be used in the layer's paint like: `['boolean', ['feature-state', 'hovered'], false]`)
 * @param minZoomLevel The min zoom level that this featureState/popupState will work at
 * @returns [the current feature state, a function to call when the feature state should be re-evaluated]
 */
export const useFeatureState = <PropertiesType>(params: {
  map: MapRef | undefined
  sources: MapSource[]
  featureStateKey: keyof typeof MapFeatureState
  options?: UseFeatureStateOptions
}): UseFeatureStateReturnType<PropertiesType> => {
  const { map, sources, featureStateKey, options } = params
  const [featureState, setFeatureState] = useState<FeatureState<PropertiesType> | undefined>()

  //#region basic function to clear the feature state
  const clearFeatureState = useCallback(() => {
    // clear feature state
    // TODO: can remove the check of map?.getFeatureState after upgrading to mapbox-gl 2.13.0 https://github.com/mapbox/mapbox-gl-js/issues/11784
    if (
      featureStateStore[featureStateKey] &&
      map?.getFeatureState(featureStateStore[featureStateKey])?.[featureStateKey]
    ) {
      map?.removeFeatureState(featureStateStore[featureStateKey], featureStateKey)
    }

    if (featureStateStore[featureStateKey]) delete featureStateStore[featureStateKey]

    setFeatureState(undefined)
  }, [map, featureStateKey, featureState])
  //#endregion

  //#region basic function to update the feature state
  const updateFeatureState = useCallback(
    (lngLat: LngLatLike, geojsonFeature: FeatureStateFeature, origin: FeatureStateOrigin) => {
      clearFeatureState()

      // it's the responsibility of the developer to ensure that the properties on the map feature have the fields in PopupContentType
      setFeatureState({
        id: geojsonFeature.id,
        feature: geojsonFeature,
        lngLat: LngLat.convert(lngLat),
        properties: geojsonFeature.properties as PropertiesType,
        origin,
      })

      featureStateStore[featureStateKey] = geojsonFeature

      // it's the responsibility of the developer to ensure that the hover feature id is generated by the source or promoted from the content
      map?.setFeatureState(geojsonFeature, { [featureStateKey]: true })
    },
    [map, featureStateKey, featureState]
  )
  //#endregion

  //#region function to update the feature state given a mouse event from onMouseMove, onMouseLeave, onMouseOut, onClick, etc
  const updateFeatureStateFromMouseEvent = useCallback(
    (event: MapMouseEvent) => {
      const eventFeature = getFeatureFromMouseEvent(event, sources)
      if (isValidZoomLevel(map, options?.minZoomLevel) && eventFeature) {
        updateFeatureState(
          event.lngLat,
          eventFeature,
          FeatureStateOrigin[event.type as keyof typeof FeatureStateOrigin] // we've already checked that the event.type was an allowed origin
        )
      } else {
        clearFeatureState()
      }
    },
    [sources, map, featureStateKey, options?.minZoomLevel, featureState]
  )

  //#endregion

  //#region State to periodically query a feature from the map: when set, this is the feature we want to show up on the map, so we will periodically query the map for this feature to show it
  const [mapFeatureToQuery, setMapFeatureToQuery] = useState<FeatureQuery<PropertiesType>>()
  const intervalRef = useRef<ReturnType<typeof setInterval>>()
  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = undefined
    }
    if (!map || !mapFeatureToQuery) return

    const currentZoom = map.getZoom()
    const flyToZoomLevel = !isValidZoomLevel(map, options?.minZoomLevel)
      ? (options?.minZoomLevel ?? DEFAULT_MIN_INTERACTOIN_ZOOM_LEVEL)
      : currentZoom
    map.flyTo({
      center: mapFeatureToQuery.lngLat,
      speed: 1,
      curve: 1,
      zoom: flyToZoomLevel,
    })

    intervalRef.current = setInterval(() => {
      const queriedFeature = queryMapFeatureById(
        map,
        sources,
        mapFeatureToQuery.featureId,
        mapFeatureToQuery.idPropertyKeyOnFeature as string | undefined
      )
      if (queriedFeature) {
        updateFeatureState(
          mapFeatureToQuery?.lngLat,
          {
            ...queriedFeature,
            source: sources[0].sourceId,
            sourceLayer: sources[0].sourceLayer,
          },
          mapFeatureToQuery.origin
        )
        setMapFeatureToQuery(undefined)
      }
    }, 300)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [mapFeatureToQuery])

  //#endregion

  //#region Track url changes to clear the feature state when the query params change: map data typically changes on query params, keeping the query params

  const [urlParams] = useSearchParams()
  const urlParamKeys = JSON.stringify(
    Object.entries(Object.fromEntries(urlParams.entries())).filter(
      entry => !options?.queryParamKeysToIgnore?.includes(entry[0])
    )
  )
  useEffect(() => {
    clearFeatureState()
  }, [urlParamKeys])

  //#endregion

  return {
    featureState,
    updateFeatureStateFromMouseEvent,
    setMapFeatureToQuery,
    clearFeatureState,
  }
}

/**
 * Use feature state and keep it in-sync with a query param. This is useful to keep selection state
 * in-sync between the map and components that care about which feature is selected (e.g. sidebar tables).
 *
 * Built on top of `useFeatureState` in order to keep the functionality of tracking feature state
 * in-memory (the typical use-case) separate from keeping it in-sync with a query param.
 *
 * To keep it in sync, we need to:
 * 1. Update the feature state with the query param on load, and navigate to that feature on the map
 * 2. Update the feature state when the query param changes (e.g. from clicking on a table in the sidebar) and navigate to that feature on the map
 * 3. Update the query param when the feature state changes (e.g. from a mouse-event)
 *
 * Note: The featureId query param can be a number, but internally, it will always be extracted and compared as a string
 *
 * @param map see `useFeatureState`
 * @param sources see `useFeatureState`
 * @param featureStateKey see `useFeatureState`
 * @param queryParamKey the query param key to store the feature ID string in
 * @param idPropertyKeyOnFeature the feature property that represents the id for this feature, or undefined to use the canonical feature id
 *   See this issue for why using promoteId then grabbing the feature id isn't sufficient: https://github.com/mapbox/mapbox-gl-js/issues/2716
 *   (TLDR: string ids in vector tiles get converted to a random index, so you'll need to use a property that represents the id instead of the canonical id)
 * @param fetchFeatureLngLat async function to get the location of the feature to navigate to on the map
 * @param options see `useFeatureState`
 * @returns same object as `useFeatureState`
 */
export const useFeatureStateWithQueryParam = <PropertiesType>(params: {
  map: MapRef | undefined
  sources: MapSource[]
  featureStateKey: keyof typeof MapFeatureState
  queryParamKey: string
  idPropertyKeyOnFeature?: keyof PropertiesType
  fetchFeatureLngLat: (featureId: string) => Promise<LngLatLike | undefined>
  options?: UseFeatureStateOptions
}): UseFeatureStateReturnType<PropertiesType> => {
  const {
    map,
    sources,
    featureStateKey,
    queryParamKey,
    idPropertyKeyOnFeature,
    fetchFeatureLngLat,
    options,
  } = params

  const {
    featureState,
    updateFeatureStateFromMouseEvent,
    setMapFeatureToQuery,
    clearFeatureState,
  } = useFeatureState<PropertiesType>({
    map,
    sources,
    featureStateKey,
    options: {
      ...options,
      queryParamKeysToIgnore: [queryParamKey, ...(options?.queryParamKeysToIgnore ?? [])],
    },
  })

  const featureId = (
    idPropertyKeyOnFeature ? featureState?.properties[idPropertyKeyOnFeature] : featureState?.id
  )?.toString()

  const [queryParams, setQueryParams] = useQueryParams(
    queryParamKey ? { [queryParamKey]: StringParam } : {}
  )

  const sourcesLoaded = useMapSourcesLoaded(map, sources)

  //#region Track if feature state has been initialized so that we don't try to override the query param value with an empty feature state

  const [hasFeatureStateBeenInitialized, setHasFeatureStateBeenInitialized] = useState(false)
  useEffect(() => {
    if (!hasFeatureStateBeenInitialized && featureState) setHasFeatureStateBeenInitialized(true)
  }, [featureState, hasFeatureStateBeenInitialized])

  //#endregion

  //#region Update the query param when feature state is updated from mouse events (except on first load when we don't want to override the query param with an undefined featureState)

  useEffect(() => {
    if (featureId !== queryParams[queryParamKey] && hasFeatureStateBeenInitialized) {
      setQueryParams({ [queryParamKey]: featureId })
      setMapFeatureToQuery(undefined)
    }
  }, [featureId, hasFeatureStateBeenInitialized]) // putting `queryParams` in the deps will cause infinite loops

  //#endregion

  //#region Go to the feature location if the query param changed

  useEffect(() => {
    // Wait until all sources are loaded before trying to auto-navigate to any feature.
    if (!sourcesLoaded) return
    // Navigate to the query param feature when the query param is different from the current feature states
    const queryParamFeatureId = queryParams[queryParamKey]
    if (queryParamFeatureId && queryParamFeatureId !== featureId) {
      fetchFeatureLngLat(queryParamFeatureId).then(lngLat => {
        if (!lngLat) return
        setMapFeatureToQuery({
          lngLat,
          featureId: queryParamFeatureId,
          idPropertyKeyOnFeature: idPropertyKeyOnFeature,
          origin: FeatureStateOrigin.QUERY_PARAM,
        })
      })
    } else if (!queryParamFeatureId) {
      // hide existing feature when query param is removed
      clearFeatureState()
    }
  }, [sourcesLoaded, fetchFeatureLngLat, queryParams]) // putting `featureState` in the deps will break it

  //#endregion

  return {
    featureState,
    updateFeatureStateFromMouseEvent,
    setMapFeatureToQuery,
    clearFeatureState,
  }
}

export const getFeatureFromMouseEvent = (event: MapMouseEvent, sources: MapSource[]) => {
  const eventFeature = event?.features?.[0] as FeatureStateFeature | undefined
  return eventFeature &&
    featureStateOrigins.includes(event.type) &&
    sources.some(
      source =>
        eventFeature.source === source.sourceId && eventFeature.sourceLayer === source.sourceLayer
    )
    ? eventFeature
    : undefined
}

/**
 * Queries the map by a feature id or property. Since this uses `querySourceFeatures` it is not guaranteed that the features will be available.
 * @param map the map references
 * @param sources the map data sources to query
 * @param featureId the id or property value to filter features by
 * @param idPropertyKeyOnFeature the property key on the feature, undefined to use the canonical feature ids
 * @returns the feature, if found
 */
const queryMapFeatureById = (
  map: MapRef | undefined,
  sources: { sourceId: string; sourceLayer?: string }[],
  featureId: string,
  idPropertyKeyOnFeature?: string
) => {
  return sources.reduce<FeatureStateFeature | undefined>((queriedFeature, currentSource) => {
    if (queriedFeature) return queriedFeature
    return map
      ?.querySourceFeatures(currentSource.sourceId, {
        sourceLayer: currentSource.sourceLayer,
        filter: [
          '==',
          ['to-string', idPropertyKeyOnFeature ? ['get', idPropertyKeyOnFeature] : ['id']],
          featureId,
        ],
      })
      ?.find(
        feature =>
          (idPropertyKeyOnFeature
            ? feature.properties?.[idPropertyKeyOnFeature]?.toString()
            : feature.id?.toString()) === featureId
      ) as unknown as FeatureStateFeature
  }, undefined)
}

/**
 * Track the first time all map sources are loaded.
 * @param map
 * @param sources
 * @returns
 */
const useMapSourcesLoaded = (map: MapRef | undefined, sources: MapSource[]): boolean => {
  const [sourcesLoaded, setSourcesLoaded] = useState(false)
  useEffect(() => {
    const maybeSetSourcesLoaded = () => {
      if (
        sources.every(
          source => map?.getSource(source.sourceId) && map?.isSourceLoaded(source.sourceId)
        )
      ) {
        setSourcesLoaded(true)
      }
    }

    map?.on('sourcedata', maybeSetSourcesLoaded)
    return () => {
      map?.off('sourcedata', maybeSetSourcesLoaded)
    }
  }, [map, sources])

  return sourcesLoaded
}

const isValidZoomLevel = (map: MapRef | undefined, minZoomLevel: number | undefined) =>
  (map?.getZoom() ?? -1) >= (minZoomLevel ?? DEFAULT_MIN_INTERACTOIN_ZOOM_LEVEL)
