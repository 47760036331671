/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CDSCurbsPoliciesResponse } from '../models/CDSCurbsPoliciesResponse'
import type { CDSCurbsZonesResponse } from '../models/CDSCurbsZonesResponse'
import type { CurbZoneStatisticsResponseModel } from '../models/CurbZoneStatisticsResponseModel'
import type { ISODOW } from '../models/ISODOW'
import type { Page_ShapesResultModel_ } from '../models/Page_ShapesResultModel_'
import type { Page1K_FilteredCurbPolicy_ } from '../models/Page1K_FilteredCurbPolicy_'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class CurbsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Curb Policies Filters
   * Returns a list of policy_id, rule_ids that conform to applied filters
   * @returns Page1K_FilteredCurbPolicy_ Successful Response
   * @throws ApiError
   */
  public getCurbPoliciesFilters({
    regionId,
    purposes,
    vehicleTypes,
    userClasses,
    types,
    hasRate,
    maximum,
    unit,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    page = 1,
    size = 300,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Filter for purposes
     */
    purposes?: Array<string> | null
    /**
     * Filter for vehicle_types
     */
    vehicleTypes?: Array<string> | null
    /**
     * Filter for user classes
     */
    userClasses?: Array<string> | null
    /**
     * Filter for types
     */
    types?: Array<string> | null
    /**
     * True to filter for policy rules with rates
     */
    hasRate?: boolean | null
    /**
     * Filter policy rule maximum by providing two numbers representing a range (inclusive)
     */
    maximum?: Array<number> | null
    /**
     * Filter by policy rule unit
     */
    unit?: string | null
    /**
     * Filter policy rules that apply on or after the provided ISO date (inclusive)
     */
    startDate?: string | null
    /**
     * Filter policy rules that apply on or before the provided ISO date (inclusive)
     */
    endDate?: string | null
    /**
     * Filter policy rules that apply after the provided time in format HH:mm (inclusive)
     */
    startTime?: string | null
    /**
     * Filter policy rules that apply before the provided time in format HH:mm (exclusive)
     */
    endTime?: string | null
    /**
     * Filter policy rules for those that apply weekly on ISO days of week
     */
    daysOfWeek?: Array<number> | null
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
  }): CancelablePromise<Page1K_FilteredCurbPolicy_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curbs/policies/filter',
      path: {
        region_id: regionId,
      },
      query: {
        purposes: purposes,
        vehicle_types: vehicleTypes,
        user_classes: userClasses,
        types: types,
        has_rate: hasRate,
        maximum: maximum,
        unit: unit,
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        days_of_week: daysOfWeek,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Activity Statistics
   * Returns the count of shapes in the region that are associated
   * with each policy type (e.g. loading, no_stopping, parking, etc.)
   * @returns number Successful Response
   * @throws ApiError
   */
  public getCurbActivityStatistics({
    regionId,
    policyIds,
    purposes,
    vehicleTypes,
    userClasses,
    types,
    hasRate,
    maximum,
    unit,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * List of policy IDs to filter by
     */
    policyIds?: Array<string> | null
    /**
     * Filter for purposes
     */
    purposes?: Array<string> | null
    /**
     * Filter for vehicle_types
     */
    vehicleTypes?: Array<string> | null
    /**
     * Filter for user classes
     */
    userClasses?: Array<string> | null
    /**
     * Filter for types
     */
    types?: Array<string> | null
    /**
     * True to filter for policy rules with rates
     */
    hasRate?: boolean | null
    /**
     * Filter policy rule maximum by providing two numbers representing a range (inclusive)
     */
    maximum?: Array<number> | null
    /**
     * Filter by policy rule unit
     */
    unit?: string | null
    /**
     * Filter policy rules that apply on or after the provided ISO date (inclusive)
     */
    startDate?: string | null
    /**
     * Filter policy rules that apply on or before the provided ISO date (inclusive)
     */
    endDate?: string | null
    /**
     * Filter policy rules that apply after the provided time in format HH:mm (inclusive)
     */
    startTime?: string | null
    /**
     * Filter policy rules that apply before the provided time in format HH:mm (exclusive)
     */
    endTime?: string | null
    /**
     * Filter policy rules for those that apply weekly on ISO days of week
     */
    daysOfWeek?: Array<number> | null
  }): CancelablePromise<Record<string, number>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curbs/policies/curb_activity_statistics',
      path: {
        region_id: regionId,
      },
      query: {
        policy_ids: policyIds,
        purposes: purposes,
        vehicle_types: vehicleTypes,
        user_classes: userClasses,
        types: types,
        has_rate: hasRate,
        maximum: maximum,
        unit: unit,
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        days_of_week: daysOfWeek,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Shapes
   * @returns Page_ShapesResultModel_ Successful Response
   * @throws ApiError
   */
  public getCurbShapes({
    regionId,
    page = 1,
    size = 50,
    shapeLayerUuid,
    shapeUuid,
    shapeId,
    shapeName,
    shapeDescription,
    sideOfStreet,
    search,
    orderBy,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
    shapeLayerUuid?: string | null
    shapeUuid?: string | null
    shapeId?: string | null
    shapeName?: string | null
    shapeDescription?: string | null
    sideOfStreet?: string | null
    search?: string | null
    orderBy?: string | null
  }): CancelablePromise<Page_ShapesResultModel_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curbs/shapes',
      path: {
        region_id: regionId,
      },
      query: {
        page: page,
        size: size,
        shape_layer_uuid: shapeLayerUuid,
        shape_uuid: shapeUuid,
        shape_id: shapeId,
        shape_name: shapeName,
        shape_description: shapeDescription,
        side_of_street: sideOfStreet,
        search: search,
        order_by: orderBy,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Zone Length Lookup
   * Returns a dictionary with shape uuids as keys and values as dictionaries
   * containing the length and number of spaces from the cds_zones table.
   * @returns CurbZoneStatisticsResponseModel Successful Response
   * @throws ApiError
   */
  public getCurbZoneLengthLookup({
    regionId,
    shapeLayerUuid,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
  }): CancelablePromise<Record<string, CurbZoneStatisticsResponseModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curbs/zone_length_lookup',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Allocation By Time For Layer
   * Returns a dictionary in which the keys are a policy type and each
   * value is a list of 96 integers. Each value in the list is a curb length
   * in centimeters describing how much curb is allocated to that policy type
   * at that time, where the time component comes from breaking the day into
   * 15 minute chunks (24 * 60 / 15 = 96). Optionally filtered by a boundary shape
   * or layer.
   * @returns number Successful Response
   * @throws ApiError
   */
  public getCurbAllocationByTimeForLayer({
    regionId,
    shapeLayerUuid,
    isoDow,
    boundaryShapeLayerUuid,
    boundaryShapeUuids,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Day of week to use for calculation as an ISO weekday number (1-7)
     */
    isoDow: ISODOW
    /**
     * Optional shape layer boundary by which to filter the curb shapes
     */
    boundaryShapeLayerUuid?: string | null
    /**
     * Optional shape boundary by which to filter the curb shapes
     */
    boundaryShapeUuids?: Array<string> | null
  }): CancelablePromise<Record<string, Array<number>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curbs/allocation_by_time',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        iso_dow: isoDow,
        boundary_shape_layer_uuid: boundaryShapeLayerUuid,
        boundary_shape_uuids: boundaryShapeUuids,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Cds Policies
   * @returns CDSCurbsPoliciesResponse Successful Response
   * @throws ApiError
   */
  public getCdsPolicies({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<CDSCurbsPoliciesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curbs/cds/policies',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Cds Zones
   * @returns CDSCurbsZonesResponse Successful Response
   * @throws ApiError
   */
  public getCdsZones({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<CDSCurbsZonesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curbs/cds/zones',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
