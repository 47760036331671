/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest'
import type { OpenAPIConfig } from './core/OpenAPI'
import { FetchHttpRequest } from './core/FetchHttpRequest'
import { ConfigService } from './services/ConfigService'
import { CurbParkingInsightsService } from './services/CurbParkingInsightsService'
import { CurbsService } from './services/CurbsService'
import { DefaultService } from './services/DefaultService'
import { FeatureFlagsService } from './services/FeatureFlagsService'
import { LayersService } from './services/LayersService'
import { MobilityService } from './services/MobilityService'
import { OperatorsService } from './services/OperatorsService'
import { OrganizationsService } from './services/OrganizationsService'
import { ParkingService } from './services/ParkingService'
import { PermissionsService } from './services/PermissionsService'
import { PoliciesV1Service } from './services/PoliciesV1Service'
import { PoliciesV2Service } from './services/PoliciesV2Service'
import { PricingEngineService } from './services/PricingEngineService'
import { RegionsService } from './services/RegionsService'
import { ShapesService } from './services/ShapesService'
import { UsersService } from './services/UsersService'
import { VehiclesService } from './services/VehiclesService'
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest
export class InternalApi {
  public readonly config: ConfigService
  public readonly curbParkingInsights: CurbParkingInsightsService
  public readonly curbs: CurbsService
  public readonly default: DefaultService
  public readonly featureFlags: FeatureFlagsService
  public readonly layers: LayersService
  public readonly mobility: MobilityService
  public readonly operators: OperatorsService
  public readonly organizations: OrganizationsService
  public readonly parking: ParkingService
  public readonly permissions: PermissionsService
  public readonly policiesV1: PoliciesV1Service
  public readonly policiesV2: PoliciesV2Service
  public readonly pricingEngine: PricingEngineService
  public readonly regions: RegionsService
  public readonly shapes: ShapesService
  public readonly users: UsersService
  public readonly vehicles: VehiclesService
  public readonly request: BaseHttpRequest
  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = FetchHttpRequest
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? 'dev',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    })
    this.config = new ConfigService(this.request)
    this.curbParkingInsights = new CurbParkingInsightsService(this.request)
    this.curbs = new CurbsService(this.request)
    this.default = new DefaultService(this.request)
    this.featureFlags = new FeatureFlagsService(this.request)
    this.layers = new LayersService(this.request)
    this.mobility = new MobilityService(this.request)
    this.operators = new OperatorsService(this.request)
    this.organizations = new OrganizationsService(this.request)
    this.parking = new ParkingService(this.request)
    this.permissions = new PermissionsService(this.request)
    this.policiesV1 = new PoliciesV1Service(this.request)
    this.policiesV2 = new PoliciesV2Service(this.request)
    this.pricingEngine = new PricingEngineService(this.request)
    this.regions = new RegionsService(this.request)
    this.shapes = new ShapesService(this.request)
    this.users = new UsersService(this.request)
    this.vehicles = new VehiclesService(this.request)
  }
}
